let buildIdHelper: string = '11418';

export const getBuildId: () => string = () => {
    if (buildIdHelper.includes('--BN--')) {
        buildIdHelper = `dev-${Math.round(Math.random() * 10000)}`;
    }
    return buildIdHelper;
};

export const getBuildIdQuery: (useAmp?: boolean) => string = (useAmp: boolean = false) => `${useAmp ? '&' : '?'}build=${getBuildId()}`;
